import React, { useEffect, useState } from "react";
// import "../css/login.css";
import base64url from "base64url";
import cryptoBrowserify from "crypto-browserify";
// import { useAuthStore } from "../state/auth";

//https://alexandria.auth.eu-west-1.amazoncognito.com/login?client_id=2fjladm7dn29o79am6kised3u2&response_type=token&scope=openid+phone&redirect_uri=http://localhost:3000/login
const COGNITO_FEDERATED_URL_SIGN_UP = `https://${process.env.REACT_APP_COGNITO_IDENTITY_DOMAIN}.auth.${process.env.REACT_APP_COGNITO_REGION}.amazoncognito.com/oauth2/authorize?redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI_LOGIN}&response_type=code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&scope=${process.env.REACT_APP_COGNITO_SCOPE}&identity_provider=Identity`;
const COGNITO_FEDERATED_URL_SIGN_OUT = `https://${process.env.REACT_APP_COGNITO_IDENTITY_DOMAIN}.auth.${process.env.REACT_APP_COGNITO_REGION}.amazoncognito.com/logout??response_type=code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&logout_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI_LOGOUT}&identity_provider=Identity`;
const COGNITO_FEDERATED_URL_TOKEN = `https://${process.env.REACT_APP_COGNITO_IDENTITY_DOMAIN}.auth.${process.env.REACT_APP_COGNITO_REGION}.amazoncognito.com/oauth2/token`;

console.log({ COGNITO_FEDERATED_URL_SIGN_UP, COGNITO_FEDERATED_URL_SIGN_OUT });

const sha256 = (buffer) => {
  return cryptoBrowserify.createHash("sha256").update(buffer).digest();
};

const sendEvent = (worker, data) =>
  new Promise((res, rej) => {
    const channel = new MessageChannel();

    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();

      if (data.error) {
        rej(data.error);
      } else {
        res(data.result);
      }
    };

    worker.active.postMessage([data], [channel.port2]);
  });

export default function Login({ children }) {
  const nonce = window.localStorage.getItem("nonce"); //CSRF
  const codeVerifier = window.localStorage.getItem("codeVerifier"); //PKCE
  const url = new URL(window.location.href.replace(/#/g, "?"));
  const [worker, setWorker] = useState(null);
  const [code, _] = useState(url.searchParams.get("code"));
  const [state, __] = useState(url.searchParams.get("state"));
  const [challenge, setChallenge] = useState(null);
  const federatedURL = `${COGNITO_FEDERATED_URL_SIGN_UP}&state=${nonce}&code_challenge_method=${process.env.REACT_APP_COGNITO_CODE_CHALLENGE_METHOD}&code_challenge=${challenge}`;
  // const setAuthenticated = useAuthStore((state) => state.setAuthenticated);
  useEffect(() => {
    if (!codeVerifier) {
      window.localStorage.setItem(
        "codeVerifier",
        base64url(cryptoBrowserify.randomBytes(32))
      );
    }

    if (!nonce) {
      window.localStorage.setItem(
        "nonce",
        base64url(cryptoBrowserify.randomBytes(16))
      );
    }

    setChallenge(
      base64url(sha256(window.localStorage.getItem("codeVerifier")))
    );

    navigator.serviceWorker.ready.then((worker) => {
      setWorker(worker);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (worker)
        sendEvent(worker, {
          type: "HAVE_VALID_CREDENTIALS",
        }).then((result) => {
          console.log(result);
          if (result) {
            console.log('entra aqui')
            window.location.href = "/documentation";
            clearInterval(interval);
            return;
          } else {
            window.location.href = federatedURL;
          }
        });
    }, 1000);

    return () => clearInterval(interval);
  }, [worker]);

  // useEffect(() => {
  //   if (worker)
  //     sendEvent(worker, {
  //       type: "HAVE_VALID_CREDENTIALS",
  //     }).then((result) => {
  //       result && setIsAllow(true);
  //     });
  // }, [worker]);

  useEffect(() => {
    if (code && worker && state && state === nonce) {
      window.localStorage.removeItem("nonce");
      window.localStorage.removeItem("codeVerifier");

      sendEvent(worker, {
        type: "SET_AUTH_DATA",
        payload: {
          code,
          codeVerifier,
          clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
          clientSecret: process.env.REACT_APP_COGNITO_CLIENT_SECRET,
          redirectUri: process.env.REACT_APP_COGNITO_REDIRECT_URI_LOGIN,
          tokenUrl: COGNITO_FEDERATED_URL_TOKEN,
          singOutUrl: COGNITO_FEDERATED_URL_SIGN_OUT,
        },
      }).catch((error) => {
        console.error(error);
      });
    }
  }, [code, worker, state]);

  return (
    <>
      {/* {code ? (
        <div></div>
      ) : (
        <button
          className="login"
          onClick={() => {
            window.location.href = federatedURL;
          }}
        >
          Conectarse
        </button>
      )} */}
    </>
  );
}
